<template>
    <div class="scroll-container" v-html="htmlContent"></div>
  
</template>


<script>
import Axios from 'axios';
import { API_BASE_URL, TENANT } from '../api/constants';
import { ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS } from '../store/constants';
export default {
    mounted() {
    
        if (this.$route.query.extId) {
            this.myId = this.$route.query.extId
        }
        if (this.$route.query.tenant) {
            this.tenant = this.$route.query.tenant
        }
        this.showPage()
    },
    data() {
        return {
            myId: "",
            tenant: "",
            htmlContent: "<h1>Loading Content</h1>"
        }
    },
    methods: {
        showPage() {
            this.$store.dispatch("showLoading");
            const baseUrl = API_BASE_URL + "extPage";
            let url = new URL(baseUrl)
            url.searchParams.append('tenant', this.tenant)
            url.searchParams.append('extId', this.myId)
            Axios.get(url)
                .then((response) => {
                    this.$store.dispatch("hideLoading");
                    this.htmlContent = response.data.HtmlPage

                })
                .catch((error) => {
                    this.$store.commit("showAlert", {
                        type: ALERT_TYPE_ERROR,
                        message:
                            error,
                    });
                    this.$store.dispatch("hideLoading");
                });
        }
    }
}
</script>

<style
    lang="scss"
    scoped    
>

.scroll-container {
  height: 100vh; /* Volledige hoogte */
  overflow-y: auto; /* Scrollen als nodig */

}</style>