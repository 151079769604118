<template>
  <div>
    <v-row
      v-if="loading"
      class="py-0 pb-2 px-6"
      align="center"
      justify="center"
      style="height: 100%"
    >
      <v-progress-linear
        indeterminate
        rounded
        color="primary"
      >
      </v-progress-linear>
    </v-row>
    <v-container
      fluid
      class="fill-height py-0"
    >
      <v-card
        class="kingSize card mb-2 outlined rounded-lg"
        flat
        v-for="(item, index) in getItems"
        :key="index"
      >
        <div
          class="d-flex px-4"
          v-if="item.isDeleted !== 1"
        >
          <v-list-item-avatar
            size="32"
            color="grey"
            class="align-self-start mt-4"
          >
            <img
              v-if="item.Employee && getImage(item.Employee)"
              :src="getImage(item.Employee)"
              crossorigin="anonymous"
              alt="Avatar"
            >
            <div
              v-else-if="getUserName(item.Employee)"
              size="24px"
              class="avatar iconButton text-caption pa-1 rounded-lg font-weight-medium"
              style="width:24px!important; height: 24px;"
              color="primary"
            >
              {{ getInitials(getUserName(item.Employee)) }}
            </div>

          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <v-container
                fluid
                class="px-0 py-1"
              >
                <v-row
                  no-gutters
                  align="center"
                >
                  <v-row
                    no-gutters
                    align="center"
                    class="flex-1"
                  >
                    <span
                      v-if="item.Employee && getUserName(item.Employee)"
                      class="text-body-2 font-weight-medium mr-2"
                    >
                      {{ getUserName(item.Employee) }}
                    </span>
                    <v-chip
                      v-if="!isEmpty(item.Recipient)"
                      x-small
                    > @ {{ getUserName(item.Recipient) }}
                    </v-chip>
                    <v-spacer />
                    <span class="text-caption gray--text"> {{
                      getTime(item.createdAt) }}</span>
                  </v-row>
                  <!-- <v-btn
                    v-if="item.Employee === getCurrentEmployeeGuid"
                    class="ml-1"
                    icon
                    small
                    @click.stop="showDelete(item)"
                  >
                    <v-icon
                      light
                      small
                      class="configLight white--text"
                    >hi-trash-outline</v-icon>
                  </v-btn> -->
                  <v-menu
                    v-if="item.Employee === getCurrentEmployeeGuid"
                    :close-on-click="true"
                    :rounded="'lg'"
                    transition="slide-y-transition"
                    offset-y
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        class="iconButton ml-2"
                        icon
                        small
                        v-on="on"
                      >
                        <v-icon
                          small
                          class="configLight"
                        >hi-ellipsis-vertical-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(action, index) in actions"
                        :key="index"
                        @click="clickMenu(action.method, item)"
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{ action.icon }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{ action.title }}</v-list-item-title>
                        </v-list-item-content>

                      </v-list-item>
                    </v-list>
                  </v-menu>

                </v-row>
              </v-container>



            </v-list-item-title>

            <v-list-item-subtitle class="text-body-2">
              <pre
                class="text-wrap-auto w-full"
                v-if="!isEditMode(item)"
              >{{ item.Message }}</pre>
              <v-container
                class="pa-0"
                v-else
              >
                <v-textarea
                  v-model="item.Message"
                  label="Write your comment here.."
                  background-color="tileBackgroundColor"
                  class="rounded-lg"
                  clearable
                  clear-icon="hi-x-mark"
                  solo
                  rows="2"
                  rounded
                  auto-grow
                  hide-details
                ></v-textarea>

                <v-container
                  fluid
                  class="py-6"
                >
                  <v-row align="center">

                    <v-autocomplete
                      v-model="item.Recipient"
                      style="max-width: 200px;"
                      solo
                      dense
                      class="text-field-transparent"
                      :items="getEmployees"
                      item-text="FullName"
                      item-value="Guid"
                      label="Notify employee"
                      hide-details
                    ></v-autocomplete>
                    <v-btn
                      elevation="0"
                      class="px-6 ml-2"
                      @click="cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="isEmpty(item.Message)"
                      elevation="0"
                      class="primary px-6 ml-2"
                      @click="() => { update(item) }"
                    >
                      Update
                    </v-btn>

                    <v-spacer />

                  </v-row>
                </v-container>
              </v-container>
              <!-- <v-textarea
                :value="item.Message"
                background-color="transparent"
                class="rounded-lg pa-0 comment"
                disabled
                rows="1"
                solo
                rounded
                auto-grow
                hide-details
              ></v-textarea> -->
            </v-list-item-subtitle>

          </v-list-item-content>



        </div>
        <v-list-item v-else>
          <v-list-item-title>
            <v-icon
              light
              class="configLight white--text mr-1"
            >hi-trash-outline</v-icon> Message will be removed on next sync...
          </v-list-item-title>
        </v-list-item>
        <!-- <v-avatar
                :color="item.from == 'user' ? 'indigo' : 'red'"
                size="36"
              >
                <span class="white--text">{{ item.Employee }}</span>
              </v-avatar>
              <span
                v-if="item.from != 'user'"
                class="blue--text ml-3"
              >{{ item.Message }}</span> -->
      </v-card>


    </v-container>

    <v-container fluid>
      <v-btn
        v-if="showCommentButton"
        elevation="0"
        class="tileBackgroundColor px-6"
        @click="() => {
          isReply = true
        }
        "
      >
        Add comment
      </v-btn>
      <v-card
        v-else
        elevation="0"
        class="kingSize tileBackgroundColor  rounded-lg"
      >

        <v-textarea
          v-model="newComment.Message"
          label="Write your comment here.."
          background-color="tileBackgroundColor"
          class="rounded-lg"
          clearable
          clear-icon="hi-x-mark"
          solo
          rows="2"
          rounded
          auto-grow
          hide-details
        ></v-textarea>

        <v-container
          fluid
          class="pa-6"
        >
          <v-row align="center">
            <v-btn
              :disabled="isEmpty(newComment.Message)"
              elevation="0"
              class="primary px-6 mr-2"
              @click="send"
            >
              Send
            </v-btn>
            <v-autocomplete
              v-model="newComment.Recipient"
              style="max-width: 200px;"
              solo
              dense
              class="text-field-transparent"
              :items="getEmployees"
              item-text="FullName"
              item-value="Guid"
              label="Notify employee"
              hide-details
            ></v-autocomplete>

            <v-spacer />

          </v-row>
        </v-container>

      </v-card>
      <v-dialog
        v-model="clearDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to delete this comment?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="clearDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click="deleteItem"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Axios from 'axios';
import { getAccessToken } from '../../store/authorizationContext';
import { ALERT_TYPE_ERROR, EMPLOYEE_MAIN, GET_VIEW_CONFIG_FOR_ID } from '../../store/constants';
import { copyObject, getGuid, getInitials, isEmpty, isTrue } from '../../api/utilities';
import moment from 'moment';

import { API_BASE_URL } from '../../api/constants';
import { mapGetters } from 'vuex';
import { SYNC_STATE_COMPLETED } from '../../store/synchronisationContext';
export default {
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      items: [],
      clearDialog: false,
      itemToDelete: null,
      isReply: false,
      loading: false,
      editItem: null,
      newComment: {
        Message: '',
        Recipient: null
      },
      actions: [
        {
          title: "Edit",
          icon: "hi-pencil-square-outline",
          method: 'edit'
        },
        {
          title: "Delete",
          icon: "hi-trash-outline",
          method: 'showDelete'
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['getSynchronisationState', 'getCurrentEmployeeGuid']),

    showCommentButton() {
      if (isTrue(this.isReply) || (this.getItems && this.getItems.length === 0)) return false
      return true
    },
    getStyle() {
      return "display: inline-block; height: 200px;";
    },
    getEmployees() {
      const employees = this.$store.getters.getDataObjectsForObjectType(EMPLOYEE_MAIN)
      return employees
    },
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    emptyToolBar() {
      return [[], [], []];
    },
    getViewConfig() {
      return this.$store.getters[GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    getObjects() {
      return this.$store.getters.getDataObjectsForObjectType(
        this.getObjectType
      );
    },
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined) {
        return this.object;
      }
      return this.$store.getters.getCurrentObject;
    },
    getParentObjectType() {
      if (!isEmpty(this.$store.getters.getObjectTypeForViewDialog)) {
        return this.$store.getters.getObjectTypeForViewDialog;
      }
      return this.$store.getters.getCurrentSplitCardObjectType
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getItems() {
      const map = new Map(this.items.map((obj) => [obj.Guid, true]));
      // var newItems = copyObject(this.items)
      this.getFilteredObjects.map(item => {
        if (!map.has(item.Guid)) {
          this.items.push(item)
        }
      })
      this.items.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      return this.items
    },
    getFilteredObjects() {
      var objects = this.getObjects;
      var payload = {
        objects: objects,
        viewConfig: this.getViewConfig,
        object: this.getCurrentObject,
        isEdit: true
      };

      var filteredObject = this.$store.getters.getFilteredObjectsForViewConfig(
        payload
      );

      // var items = []
      if (filteredObject) {
        filteredObject.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      }

      //Todo filter
      return filteredObject;
    },
  },
  mounted() {
    if (this.object && this.object.Guid) {
      this.fetchItems()
    }
  },
  watch: {
    object(value) {
      this.items = []
      this.fetchItems()
    },
    getSynchronisationState(value) {
      if (value === SYNC_STATE_COMPLETED) {
        this.fetchItems()
      }
    }
  },
  methods: {
    isEmpty,
    isEditMode(item) {
      return this.editItem && this.editItem.Guid === item.Guid && item.Employee === this.getCurrentEmployeeGuid
    },
    clickMenu(method, item) {
      this[method](item);
    },
    edit(item) {
      this.editItem = copyObject(item)
    },
    cancel() {
      this.editItem = null
    },
    update(item) {
      const oldObject = this.editItem
      var payload = {
        objectType: this.getObjectType,
        object: item,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.editItem = null
    },
    deleteItem() {
      const oldObject = copyObject(this.itemToDelete)
      this.itemToDelete.isDeleted = 1
      var payload = {
        objectType: this.getObjectType,
        object: this.itemToDelete,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.clearDialog = false
    },
    showDelete(item) {
      this.itemToDelete = item
      this.clearDialog = true
    },
    send() {
      var comment = this.newComment
      comment.Guid = getGuid()
      var config = this.getObjectConfig;
      var relatedObject = {
        object: this.object,
        viewConfig: this.getViewConfig
      }
      comment = config.afterCreate(comment, relatedObject, this.$store);
      comment.Employee = this.getCurrentEmployeeGuid
      var payload = {
        objectType: this.getObjectType,
        object: comment
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
      this.reset()
    },
    reset() {
      this.isReply = false
      this.newComment = {
        Message: '',
        Employee: null
      }
    },
    getInitials,
    getTime(time) {
      return moment(time).calendar()
    },
    getImage(id) {
      if (id) {
        const employee = this.getEmployee(id)
        if (employee) {
          return employee.Image
        }
      }
      return null
    },
    getUserName(id) {

      if (id) {
        const employee = this.getEmployee(id)
        if (employee) {
          return employee.FullName
        }
      }
      return null
    },
    getEmployee(id) {
      if (id) {
        return this.$store.getters.getEmployeeFromGuid(id)
      }
      return null
    },
    async fetchItems(noLoading) {

      if (isEmpty(this.object)) {
        return
      }
      if (isEmpty(noLoading)) {
        this.loading = true
      }

      var config = {
        headers: {}
      };
      var bearer = await getAccessToken(this.$store.getters.getCurrentUser);

      if (isEmpty(bearer)) {
        var error = "problem with bearer During Operation.";
        this.$store.commit("showAlert", {
          type: ALERT_TYPE_ERROR,
          message: error
        });
        return;
      }
      config.headers.Authorization = "Bearer ".concat(bearer);

      let url = new URL(`${API_BASE_URL}related`);
      url.searchParams.set('objectType', this.getObjectType);
      url.searchParams.set('parentGuid', this.object.Guid);
      url.searchParams.set('parentType', this.getParentObjectType);
      url.searchParams.set('parentTypeField', 'ParentType');
      url.searchParams.set('parentGuidField', 'ParentGuid');


      Axios.get(url, config).then(response => {

        this.loading = false
        this.items = response.data

      }, error => {
        this.loading = false
        console.log('Comments', error)
        // this.$store.commit("showAlert", {
        //   type: ALERT_TYPE_ERROR,
        //   message: error
        // });
      })
    },
  }
};
</script>
<style>
.comment.v-textarea .v-input__slot {
  padding: 0;
}

.comment.v-textarea textarea {
  margin: 0;
  height: auto !important;
}
</style>
